:root {
  --bot-balloon-background: linear-gradient(to right, #6D4F8F, #8D5EA4);
  --bot-balloon-font-color: #fff;
  --user-balloon-background: #F2F2F2;
  --user-balloon-font-color: #000;
}

/* .message-operator.buttons-message {
  padding: 0;
  max-width: 90%;
}

.message-visitor + .message-operator {
  margin-top: 9px;
}

.message {
  padding: 10px 16px;
  border-radius: 20px;
  margin: 2px 0;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out;
}

.message-operator.buttons-message .message-with-buttons {
  max-width: 100%;
}

.message-operator .message-with-buttons {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  max-width: 95%;
}

.message-operator {
  color: #06132b;
  background: #f0f2f7;
  float: left;
}

.message-operator.buttons-message .message-with-buttons-text {
  padding: 9px 16px 1px;
  line-height: 19px;
}

.message-operator .message-with-buttons .button-wrapper {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ebeef0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0;
  position: relative;
}

.message-operator .message-with-buttons button {
  min-width: 100%;
  display: block;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid #ebeef0;
  color: #007dfc;
  background: transparent;
  position: relative;
  z-index: 2;
  font-family: "Muli", sans-serif;
}

.message-operator .message-with-buttons button {
  outline: none;
}

.message-operator .message-with-buttons button:hover {
  text-decoration: underline;
}

.message-visitor {
  color: #fff;
  background: linear-gradient(332deg, #21dbdb, #2979ff);
  float: right;
} */

.webchat__stacked_indented_content{
  display: flex;
  flex-direction: column !important;
}

.webchat__stackedLayout--fromUser{
  align-items: flex-end;
}

.activityStatus__sender{
  padding-left: 25px;
  padding-right: 5px;
  font-size: 80%;
  color: #767676;
}

.css-1s8geyi{
  /* padding-left: 30px; */
  padding-left: 5px;
  padding-right: 10px;
}

.webchat__row {
  /* display: flex;
  align-self: flex-end; */
}
.webchat__row.message {
  padding: 0;
}

/* .webchat__row.message.msg-visitor {
} */

.message {
  /* min-width: 0 !important; */
  max-width: none !important;
}

.msg {
  padding: 10px 16px;
  border-radius: 40px !important;
  /* position: absolute;
  right: 0; */
  margin: 5px 5px 5px 5px !important;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: all 0.28s ease-in-out;
}

.msg-visitor {
  /* background-color: #5e6eea !important; */
  background: var(--user-balloon-background) !important;
  color: var(--user-balloon-font-color) !important;
  /* color: #000 !important; */
  border-top-right-radius: 6px !important;
  box-shadow: -2px 2px 4px #dcdcdc;
  /* box-shadow: -2px 2px 4px #b1aeae; */
  border: 0.5px solid #dcdcdc;
}

.msg-operator {
  max-width: 85% !important;
  /* background-color: white !important; */
  background: var(--bot-balloon-background) !important;
  color: var(--bot-balloon-font-color) !important;
  border-top-left-radius: 6px !important;
  box-shadow: 2px 2px 4px #b1afaf;
}

/* For Floating Action Button */
.css-nchgv3 > button {
  /* border-color: none !important; */
  /* border: 0 !important; */
  box-shadow: 0px 0px 5px 1px #c0bfbf;
}

.main {
  margin: 10px 20px 15px !important;
  border-radius: 5px !important;
  overflow: hidden;
  /* margin-left: 60px !important; */
}

.webchat__stackedLayout__avatar {
  display: flex;
  align-items: flex-end;
}

/*chat window*/

.chatWindow {
  flex: 1;
  display: flex;
  position: relative;
  /* height: 100%; */
  box-sizing: border-box;
  overflow: hidden;
}

.css-1t62idy {
  /* height: 50% !important; */
  /* max-height: inherit; */

  overflow-y: auto;
  /* flex: 1 1 1px !important; */
}

.menuButton {
  /* display: fixed; */
  position: absolute;
  left: 15px;
  bottom: 15.5px;
  color: rgb(170, 170, 170);
  background-color: #ebebed;
  padding: 9.5px 12px;
  border-radius: 5px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #1a64e8;
}

/* 
.chatWindow {
  display: flex;
  flex-direction: column;
  height: 100%;
} */

/* For Reactions */
/* .botActivityDecorator {
  min-height: 60px;
  position: relative;
}

.botActivityDecorator .botActivityDecorator__content {
  padding-left: 40px;
}

.botActivityDecorator .botActivityDecorator__buttonBar {
  list-style-type: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: absolute;
}

.botActivityDecorator .botActivityDecorator__buttonBar .botActivityDecorator__button {
  background: White;
  border: solid 1px #e6e6e6;
  margin-bottom: 2px;
  padding: 2px 5px 5px;
} */

.webchat__stackedLayout__content {
  padding-bottom: 11px;
}
/* 
.activityStatus__wrapper {
  display: flex;
  width: auto;
}

.botActivityDecorator {
  position: relative;
  width: 90px;
  padding-top: 5px;
}

.botActivityDecorator .botActivityDecorator__content {
  padding-left: 40px;
}

.botActivityDecorator .botActivityDecorator__buttonBar {
  list-style-type: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: absolute;
}

.botActivityDecorator .botActivityDecorator__buttonBar .botActivityDecorator__button {
  background: White;
  border: solid 1px #e6e6e6;
  margin: 0 5px 2px 0;
  padding: 2px 5px 5px;
}

.reaction_positive {
  background-color: green !important;
}

.reaction_negative {
  background-color: red !important;
} */

.activityStatus__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activityStatus {
  margin: 2px 5px;
}
/* 
.activityStatus * {
  padding: 0px;
} */

.activityStatus__sender{
  font-size: 70%;
}