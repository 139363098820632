.wrapper {
  position: relative;
  /* max-width: 1000px; */
  /* margin: 0 auto; */ /* Shlok */
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.title {
  color: grey;
  font-size: 18px;
}

.actions{
  margin-left: 0 !important;
}

.actions button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #555;
  text-align: center;
  cursor: pointer;
  width: 50%;
  font-size: 18px;
  border-radius: 5px;
}

/*.button-disabled {
  opacity: 0.6;
  pointer-events: none;
}*/
button[disabled] {
  opacity:0.4;
  cursor : not-allowed !important;
}


.login-form {
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  max-width: 300px;
  padding: 15px;
  margin: 10vh auto;
  z-index: 25;
  color: white;
  position: relative;
}

.name-input-container input[type="text"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
  /* color: rgb(0, 79, 145);  */
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 15px;
}

.name-input-container input[type="password"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
  /* color: rgb(0, 79, 145);  */
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 15px;
}

.name-input-container input[type="email"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
  /* color: rgb(0, 79, 145);  */
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 15px;
}

.bot-icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 0px 8px 1px;
  position: relative;
  margin: -35px auto;
}

.bot-icon img {
  width: 60px;
  margin: 6px 5px;
}

.bot-info {
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.icon-background {
  height: 30vh;
  /* background: linear-gradient(
    135deg,
    rgb(42, 39, 218) 0%,
    rgb(0, 204, 255) 100%
  ); */
  background: linear-gradient(
    135deg,
    #5d437a 0%,
    #d28af3 100%
  );
  /* linear-gradient(to right,, #8D5EA4) */
  text-align: center; 
}

.bot-section {
  flex-grow: 1;
  flex-direction: column;
}

.skip {
  text-decoration: underline;
  color: rgb(0, 79, 145) !important;
  padding-top: 15px;
}

.skip span {
  cursor: pointer;
}

.action {
  padding: 10px;
}

.ae-icon {
  border-radius: 30px;
  padding: 10px 20px;
  margin: 10% auto;
  background: white;
  box-shadow: 0px 0px 5px 2px inset;
  border: 2px solid rgb(170, 4, 255);
}

.start-chat {
  background-color: #555;
  color: white;
}

/* Shlok */

.bot-summary {
  font-size: 18px !important;
  padding: 0 30px !important;
}

.name-input{
  width: 180px;
}

#warning-message{
  visibility: hidden;
}
.start-chat {
  border-radius: 30px !important;
}

.bot-title {
  font-size: 20px;
}

.icon-background {
  display: flex;
  align-items: center;
}

#forget-pwd-form {
  display: none;
}

#footer {
  color: #555;
  font-size: small;
  padding-top: 10px;
  padding-bottom: 15px;
}

#footer:hover {
  cursor: pointer;
}