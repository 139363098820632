:root {
  --bot-balloon-background: linear-gradient(to right, #6D4F8F, #8D5EA4);
  --bot-balloon-font-color: #fff;
  /* --user-balloon-background: #F8F8FF; */
  --user-balloon-background: #F2F2F2;
  --user-balloon-font-color: #000;
}

.ac-adaptiveCard {
  max-width: 85% !important;
  /* background-color: white !important; */
  background: var(--bot-balloon-background) !important;
  color: var(--bot-balloon-font-color) !important;
  padding: 20px !important;
  border-radius: 40px !important;
  margin: 5px 5px 5px 5px !important;
  border-top-left-radius: 6px !important;
  font-size: 15px !important;
  line-height: 20px;
  box-shadow: 2px 2px 4px #b1afaf;
}

/* .ac-adaptiveCard:active, .ac-adaptiveCard:focus, div .ac-pushButton:active, div .ac-pushButton:focus, .ac-textInput:focus { */
.ac-adaptiveCard:active,
.ac-adaptiveCard:focus,
.ac-adaptiveCard > *:active,
.ac-adaptiveCard > *:focus {
  outline: none;
  -moz-outline-style: none;
}

.ac-textBlock > * {
  font-size: 15px !important;
  color: var(--bot-balloon-font-color) !important;
}

.ac-textInput {
  border-radius: 5px !important;
  border: none;
  padding: 10px;
  font-size: 14px !important;
}

.ac-container {
  border: none !important;
}

/* For button set */
/* .ac-actionSet{
    align-items: center !important;
} */

.ac-container .ac-pushButton {
  background-color: #c281cd !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 8px 0 !important;
  margin: 10px 5px !important;
  color: #fff !important;
}

.ac-multichoiceInput {
  border-radius: 5px !important;
  border: none;
  padding: 10px !important;
  font-size: 14px !important;
}

.ac-dateInput {
  border-radius: 5px !important;
  border: none;
  padding: 10px !important;
  padding-right: 5px !important;
  font-size: 14px !important;
}

/* For carousel cards */

.webchat__carousel_indented_content {
  display: flex;
  flex-direction: column;
}

.webchat__carousel_indented_content .content {
  margin: 0 !important;
}

.webchat__carousel_indented_content .ac-adaptiveCard {
  /* height:  !important; */
  max-width: 100% !important;
  height: 100% !important;
}

.webchat__carousel_indented_content .css-1j843a5 {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
}

.webchat__carousel_indented_content .css-19keqwu {
  height: 100%;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.webchat__carousel_indented_content .ac-adaptiveCard {
  border-radius: 40px !important;
}

.webchat__carousel_indented_content ul li:first-of-type .ac-adaptiveCard {
  border-top-left-radius: 6px !important;
}
